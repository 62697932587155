import React from 'react';
import qs from 'qs';

import { authService } from 'services/auth';

class Login extends React.PureComponent {
  async componentDidMount() {
    const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    authService.login({
      state: authService.encodeState(queryString)
    });
  }

  render() {
    return null;
  }
}

export default Login;
